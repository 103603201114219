import React from "react";

const TERMS = [
  {
    head: "Acceptance of Terms",
    content:
      "By registering an account on sanjaybookcentrevns.in (our website from hereon), you agree to be bound by these Terms & Conditions, including our Privacy Policy and any additional terms applicable to specific services. If you do not agree with these terms, you must not register an account or use our services.",
  },
  {
    head: "Account Security",
    content:
      "You are responsible for maintaining the confidentiality of your account login information and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.",
  },
  {
    head: "User Conduct",
    content:
      "As a registered user, you agree to use the website in a lawful manner and in accordance with these Terms & Conditions. You must not:",
    points: [
      "Use the website for any illegal purpose.",
      "Post or transmit any content that is harmful, threatening, abusive, defamatory, or otherwise objectionable.",
      "Violate any applicable local, state, national, or international law or regulation.",
      "Impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity.",
    ],
  },
  {
    head: "Termination of Account",
    content:
      "We reserve the right to suspend or terminate your account at our sole discretion, with or without notice, if we believe you have violated these Terms & Conditions or if your conduct is harmful to our website or users.",
  },
  {
    head: "Intellectual Property",
    content:
      "All content, trademarks, logos, and other intellectual property on the website are owned by us (Captain Diginaut/ Sanjay Book Centre). You may not use, reproduce, distribute, or create derivative works of this content without our explicit permission.",
  },
  {
    head: "Limitation of Liability",
    content:
      "To the fullest extent permitted by law, our website will not be liable for any direct, indirect, incidental, special, or consequential damages resulting from your use of or inability to use the website, even if we have been advised of the possibility of such damages.",
  },
  {
    head: "Modification of Terms",
    content:
      "We may update these Terms & Conditions from time to time without prior notice. Your continued use of the website after such changes constitutes your acceptance of the new terms.",
  },
  {
    head: "Contact Information",
    content:
      "If you have any questions or concerns about these Terms & Conditions, please contact us at for.ehte@gmail.com.",
  },
];

const TermsModal = ({ closeModal }) => {
  return (
    <div className="modal-container">
      <div onClick={closeModal} className="modal-overlay">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header text-center">Terms and Conditions</div>

          <hr style={{ marginBottom: "20px", marginTop: "10px" }} />

          {TERMS.map((term, index) => (
            <div key={term.head}>
              <h4>
                {index + 1}. {term.head}
              </h4>
              <p style={{ fontSize: "14px", fontWeight: 300 }}>
                {term.content}
                {term.points && (
                  <ul>
                    {term.points.map((point) => (
                      <li key={point}>{point}</li>
                    ))}
                  </ul>
                )}
              </p>
            </div>
          ))}
          <div
            className="header-signup-button"
            style={{ textAlign: "center" }}
            onClick={closeModal}
          >
            <button className="signInButton" style={{ marginLeft: "auto" }}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;
