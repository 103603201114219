import React from "react";
import avatar from "../avatar.png";
import headerBg from "../assets/img/header-bg.png";
import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <div className="relative h-32 overflow-visible px-4 md:px-11 py-2">
      <div className="w-full h-full absolute left-0 top-0">
        <img
          src={headerBg}
          alt="background"
          className="h-full md:h-auto w-full 2xl:h-[230px]"
        />
      </div>
      <div className="relative z-10 flex items-center justify-between max-w-screen-xl mx-auto">
        <div className="flex items-center">
          <img
            src={avatar}
            alt="avatar"
            className="logo [&.logo]:max-md:w-12 [&.logo]:max-md:h-12 [&.logo]:max-lg:w-12 [&.logo]:max-lg:h-12"
          />
          <div className="flex max-md:space-x-6 space-x-16 text-white font-bold max-md:ml-6 ml-20">
            <Link to="/Signin">Play Quiz</Link>
            <Link to="/packages">Quiz Packages</Link>
          </div>
        </div>
        <div className="">
          <Link to="/SignIn">
            <button className="bg-secondary py-2 px-4 text-background rounded font-bold">
              Sign In
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
