import React from "react";

const ReadMoreModal = ({ title, description, closeModal }) => {
  return (
    <div className="modal-container z-20 no-doc-scroll">
      <div onClick={closeModal} className="modal-overlay">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header text-center text-lg md:text-xl leading-5">
            {title}
          </div>

          <hr style={{ marginBottom: "20px", marginTop: "10px" }} />

          <div style={{ textAlign: "left" }}>
            <p style={{ fontSize: "14px", fontWeight: 300 }}>{description}</p>
          </div>
          <div
            className="header-signup-button"
            style={{ textAlign: "center" }}
            onClick={closeModal}
          >
            <button
              className="signInButton"
              style={{
                marginLeft: "auto",
                fontSize: "14px",
                padding: "0 20px",
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadMoreModal;
