import React, { useState } from "react";

import Arrow from "../../src/assets/img/arrow.png";
import ReadMoreModal from "../screens/ReadMoreModal.js";

export const DigitalLiteracyCard = ({
  title,
  description,
  longDescription,
  src,
  alt,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="literacy-card bg-white/80 md:bg-white rounded-2xl px-3 [&.literacy-card_h3]:max-md:text-lg [&.literacy-card_h3]:max-md:leading-5 max-md:py-5 max-md:backdrop-blur-md">
        <img src={src} alt={alt} className="max-h-[200px]" />
        <div>
          <h3>{title}</h3>
          <p>{description}</p>
          <div
            className="read-more text-primary cursor-pointer mt-2"
            onClick={() => setOpen(true)}
          >
            <h4>Read More </h4>
            <img src={Arrow} alt="arrow" />
          </div>
        </div>
      </div>
      {open && (
        <ReadMoreModal
          closeModal={() => setOpen(false)}
          title={title}
          description={longDescription}
        />
      )}
    </>
  );
};
