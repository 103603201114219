import React from "react";

const Pointer = ({ icon, src, text }) => {
  return (
    <div className="pointer [&.pointer_h3]:max-md:text-xl">
      <img src={src} alt="icon" className="pointer-icon" />
      <h3>{text}</h3>
    </div>
  );
};

export default Pointer;
