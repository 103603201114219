import React, { useEffect } from "react";
import "../styles/packages.css"; // Ensure you create the corresponding CSS file
import avatar from "../avatar.png";
import { Link } from "react-router-dom";

// const data = [
//   {
//     id: 1,
//     name: "John Doe",
//     age: 28,
//     job: "Developer",
//     location: "New York",
//   },
//   { id: 2, name: "Jane Smith", age: 34, job: "Designer", location: "London" },
//   {
//     id: 3,
//     name: "Sam Brown",
//     age: 45,
//     job: "Manager",
//     location: "San Francisco",
//   },
//   { id: 4, name: "Emily White", age: 22, job: "Intern", location: "Berlin" },
//   {
//     id: 5,
//     name: "Michael Black",
//     age: 38,
//     job: "Analyst",
//     location: "Toronto",
//   },
// ];

const DigitalLiteracyPrograms = () => {
  // const navigate = useNavigate();

  useEffect(() => {
    const container = document.getElementById("scrollableTable");

    setTimeout(() => {
      // Scroll down and to the right slightly
      container.scrollTo({ left: 100, behavior: "smooth" });

      // Scroll back to the original position after a short delay
      setTimeout(() => {
        container.scrollTo({ left: 0, behavior: "smooth" });
      }, 700); // Adjust delay to fit the desired effect
    }, 1000);
  }, []);

  return (
    <div>
      {/* <div style={{display:'flex',justifyContent:'space-around'}}>
            <img src={avatar} style={{display:"inline", width:"5vw",height:"5vw"}} onClick={()=>navigate('/')}/>
            <header className="header">
                <h1>Unlock Expert Digital Literacy at Unbeatable Prices</h1>
                <h2>Start Safeguarding Your Child's Future Today!</h2>
            </header>
            <Link to="/SignUp">
            <button className='signInButton' style={{backgroundColor:'#8668cd',color:'white',marginLeft:'30px'}}>Sign Up</button>
            </Link>
            </div> */}
      <header>
        <div className="header">
          <Link to="/">
            <img
              src={avatar}
              alt="avatar"
              style={{
                display: "inline",
                width: "50px",
                height: "50px",
                cursor: "cursor",
              }}
            />
          </Link>
          <div className="d-md-none">
            <div>Coming soon!</div>
            <h1>Unlock Expert Digital Literacy at Unbeatable Prices</h1>
            <h2>Start Safeguarding Your Child's Future Today!</h2>
          </div>
          <div
            style={{
              justifyContent: "end",
              width: "fit-content",
              marginLeft: "auto",
            }}
          >
            <Link to="/SignUp" className="header-signup-button">
              <button className="signInButton">Sign Up</button>
            </Link>
          </div>
        </div>
        <div className="welcome-content d-lg-none ">
          <div className="heading-mobile ">
            <div>Coming soon!</div>
            <h1>Unlock Expert Digital Literacy at Unbeatable Prices</h1>
            <h2>Start Safeguarding Your Child's Future Today!</h2>
          </div>
        </div>
      </header>
      {/* <div className="table-container">
                <div className="table-row header-row">
                    <div className="table-column header-column">Package</div>
                    {packages.map((pkg, index) => (
                        <div className="table-column header-column" key={index}>
                            {pkg.name}
                        </div>
                    ))}
                </div>
                {rows.map((row, rowIndex) => (
                    <div className="table-row" key={rowIndex}>
                        <div className="table-column header-column" style={{background:'white'}}>{row.title}</div>
                        {packages.map((pkg, pkgIndex) => (
                            <div className="table-column" key={pkgIndex}>
                                <div className="package-details">
                                    {Array.isArray(pkg[row.field]) ? (
                                        <ul>
                                            {pkg[row.field].map((item, itemIndex) => (
                                                <li key={itemIndex}>{item}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p>{pkg[row.field]}</p>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div> */}
      <div className="digital-literacy-container">
        {/* table for desktop */}
        <table className="table-container">
          <thead>
            <tr className="table-row header-row">
              <th className="table-column header-column">Package</th>
              {packages.map((pkg, index) => (
                <th className="table-column header-column" key={index}>
                  <span>{pkg.name}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr className="table-row" key={rowIndex}>
                <td className="table-column header-column">
                  <span
                    style={{
                      backgroundColor:
                        row.title === "Subscription" ? "#00AF27B0" : "white",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    {row.title}
                  </span>
                </td>
                {packages.map((pkg, pkgIndex) => (
                  <td className="table-column" key={pkgIndex}>
                    <div className="package-details">
                      {Array.isArray(pkg[row.field]) ? (
                        <ul>
                          {pkg[row.field].map((item, itemIndex) => (
                            <li key={itemIndex}>{item}</li>
                          ))}
                        </ul>
                      ) : (
                        <p>{pkg[row.field]}</p>
                      )}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        {/* table for small screens */}
        <div className="grid-table-container" id="scrollableTable">
          <div className="grid-table top-radius">
            <div className="grid-table-header sticky-column bg-purple-cell">
              Package
            </div>
            {packages.map((pkg, index) => (
              <div key={index} className="grid-table-header">
                {pkg.name}
              </div>
            ))}
          </div>
          <div className="grid-table">
            {rows.map((row, rowIndex) => (
              <React.Fragment key={rowIndex}>
                <div className="grid-table-cell sticky-column bg-green-cell">
                  <div
                    style={{
                      backgroundColor:
                        row.title === "Subscription" ? "#00AF27B0" : "white",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    {row.title}
                  </div>
                </div>
                {packages.map((pkg, pkgIndex) => (
                  <div key={pkgIndex} className="grid-table-cell">
                    <div className="package-details">
                      {Array.isArray(pkg[row.field]) ? (
                        <ul>
                          {pkg[row.field].map((item, itemIndex) => (
                            <li key={itemIndex}>{item}</li>
                          ))}
                        </ul>
                      ) : (
                        <p>{pkg[row.field]}</p>
                      )}
                    </div>
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>

        <footer className="footer">
          <p>
            Check out our <Link to="/SignIn">Quizzes</Link>, Play, Win and Learn
          </p>
        </footer>
      </div>
    </div>
  );
};

const packages = [
  {
    name: "Rare Digital Hero",
    description:
      "Introduces Foundational Digital Literacy Topics And Basic Online Safety Concepts Through Interactive Quizzes.",
    ageGroup: ["Beginners To Digital Literacy.", "Age: 9-10 Years"],
    topics: [
      "Balancing Online And Offline Activities",
      "Bluetooth Technology",
      "Computer Essentials",
      "Email Etiquette",
      "Internet Basics",
      "Malware Awareness",
      "Microsoft Word Basics",
      "Password Security",
      "Smartphone Basics",
    ],
    subscription: "₹99/- For 15 Quizzes Across Topics",
  },
  {
    name: "Legendary Digital Hero",
    description:
      "Expands On Foundational Knowledge With Quizzes Covering Advanced Digital Etiquette, Responsible AI Use, And Social Media Awareness.",
    ageGroup: ["Seeking To Build On To Digital Literacy.", "Age: 11-12 Years"],
    topics: [
      "Advanced Password Security",
      "Effective Search Strategies",
      "Email Best Practices",
      "Introduction To Virus/Antivirus",
      "Malware Detection And Prevention",
      "Microsoft Word & PPT Basics",
      "Phishing Awareness",
      "Recognizing Fake News",
      "Safe Internet Surfing Practices",
      "Smart Device Usage",
      "Understanding Digital Communication",
    ],
    subscription: "₹99/- For 15 Quizzes Across Topics",
  },
  {
    name: "Supreme Digital Hero",
    description:
      "Comprehensive Digital Literacy Curriculum Covering Advanced Topics Such As Malware, AI, Cyberbullying, And Social Media.",
    ageGroup: ["Deepen Understanding Of Digital Literacy.", "Age: 13-14 Years"],
    topics: [
      "Advanced Cyber Security",
      "Advanced MS Excel And PowerPoint Skills",
      "Artificial Intelligence (AI) Ethics",
      "Cyberbullying Prevention",
      "Cybercrime Awareness",
      "Digital Communication Strategies",
      "Digital Identity Protection",
      "Firewall Basics",
      "Parental Control Tools",
      "Recognizing And Combating Fake News",
      "Social Media Best Practices",
    ],
    subscription: "₹99/- For 10 Quizzes Across Topics",
  },
  {
    name: "Ultimate Digital Hero",
    description:
      "Advanced Digital Literacy Program Focusing On Digital Identity, Communication, Fake News, And Advanced MS Tools Usage.",
    ageGroup: ["Advance Level For Adept Technology Users.", "Age: 15-16 Years"],
    topics: [
      "Advanced AI Usage And Ethics",
      "Advanced Digital Identity Management",
      "Advanced MS Excel And PowerPoint Techniques",
      "Cybercrime Prevention And Awareness",
      "Effective Digital Communication Strategies",
      "Identifying And Combating Fake News",
      "Social Media Management And Ethics",
    ],
    subscription: "₹149/- For 10 Quizzes Across Topics",
  },
];

const rows = [
  { title: "What It Is?", field: "description" },
  { title: "Sustainability & Ideal Age Group", field: "ageGroup" },
  { title: "Topics Covered (Sample, Non-Exhaustive)", field: "topics" },
  { title: "Subscription", field: "subscription" },
];

export default DigitalLiteracyPrograms;
