import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/Screen1.css";
import "../styles/components.css";
import planet from "../assets/img/planet-stars.png";
import stars from "../assets/img/stars.png";
import triangles from "../assets/img/triangles.png";
import cyberRisk from "../../src/assets/img/hacker.png";
import burglar from "../../src/assets/img/burglar.png";
import mentalHealth from "../../src/assets/img/mental-health.png";
import privacy1 from "../../src/assets/img/privacy1.png";
import privacy2 from "../../src/assets/img/privacy2.png";
import privacy3 from "../../src/assets/img/privacy3.png";
import privacy4 from "../../src/assets/img/privacy4.png";
import privacy5 from "../../src/assets/img/privacy5.png";
import privacy6 from "../../src/assets/img/privacy6.png";
import comic1 from "../../src/assets/img/comic1.png";
import comic2 from "../../src/assets/img/comic2.png";
import contactBg from "../../src/assets/img/contact-bg.png";
import unsafe from "../../src/assets/img/unsafe.png";
import tick from "../../src/assets/img/tick.png";
import cross from "../../src/assets/img/cross.png";

import { TextCard } from "../components/TextCard.js";
import { CloudText } from "../components/CloudText.js";
import { SquareCard } from "../components/SquareCard.js";
import { DigitalLiteracyCard } from "../components/DigitalLiteracyCard.js";
import Pointer from "../components/Pointer.js";
import { Header } from "../components/Header.js";
import { InstagramIcon, YoutubeIcon } from "lucide-react";
import YouTube from "react-youtube";
import ContactForm from "../components/contact-us.js";

const Screen1 = () => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const threshold = 50; // Adjust as needed

      // Toggle header visibility based on scroll position
      if (scrollY > threshold) {
        setIsHeaderVisible(false);
      } else {
        setIsHeaderVisible(true);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    // <div className="screen1-container">
    //   <div className="youtube">
    //     <Youtube videoId="dXEG1iC1yjA" opts={opts} />
    //   </div>
    //   <div className="home-hero-text">
    //     Check out our{" "}
    //     <a href="/SignIn">
    //       <u>Quizzes</u>
    //     </a>
    //     , Play, Win and Learn{" "}
    //   </div>
    //   <Link to="/SignIn" className="header-signin-button">
    //     <button
    //       className="signInButton"
    //       style={{ position: "absolute", top: "10px", right: "30px" }}
    //     >
    //       Sign In
    //     </button>
    //   </Link>
    //   <Link to="/SignIn">
    //     <button
    //       className="signInButton"
    //       style={{
    //         backgroundColor: "transparent",
    //         color: "white",
    //         position: "absolute",
    //         top: "15px",
    //         left: "15vw",
    //       }}
    //     >
    //       Play Quiz
    //     </button>
    //   </Link>
    //   <Link to="/packages" className="header-link">
    //     <button
    //       className="signInButton"
    //       style={{
    //         backgroundColor: "transparent",
    //         color: "white",
    //         position: "absolute",
    //         top: "15px",
    //         left: "25vw",
    //       }}
    //     >
    //       Quiz Packages
    //     </button>
    //   </Link>
    //   <img src={part1} alt="Background Image" />
    //   <div style={{ position: "relative" }}>
    //     <img src={part2} alt="Background Image" />
    //     <div className="nurture">
    //       <div>
    //         <span>Good Digital Habits</span>
    //       </div>
    //       <div>
    //         <span>Avoid Digital Risk</span>
    //       </div>
    //       <div>
    //         <span>Use Technology but responsibly</span>
    //       </div>
    //       <div>
    //         <span>Tips and Tricks</span>
    //       </div>
    //     </div>
    //   </div>
    //   <img src={part3} alt="Background Image" />
    //   <img src={part4} alt="Background Image" />
    //   <div
    //     style={{
    //       display: "flex",
    //       justifyContent: "center",
    //       gap: "30px",
    //       position: "relative",
    //       top: "-100px",
    //     }}
    //     className="home-social-container"
    //   >
    //     <a href="https://www.youtube.com/@captaindiginaut" target="_blank" rel="noreferrer">
    //       <img src={youtube} className="home-social-icons" alt="youtube" />
    //     </a>
    //     <a href="https://www.instagram.com/captain_diginaut/" target="_blank" rel="noreferrer">
    //       <img src={insta} alt="instagram" className="home-social-icons" />
    //     </a>
    //   </div>
    //   <div className="content">
    //     <p>Contact Us</p>
    //     <h3>Let's get in touch</h3>
    //     <form>
    //       <div
    //         style={{ display: "flex", justifyContent: "center", gap: "30px" }}
    //         className="contact-form"
    //       >
    //         <div className="form-group-group">
    //           <input type="text" id="fullname" placeholder="Full Name" name="fullname" required />
    //         </div>
    //         <div className="form-group-group">
    //           <input type="email" id="email" placeholder="Email" name="email" required />
    //         </div>
    //       </div>
    //       <div className="form-group-group">
    //         <input
    //           id="subject"
    //           placeholder="Subject"
    //           name="email"
    //           // style={{ width: "73vw" }}
    //           required
    //         />
    //       </div>
    //       <div className="form-group-group">
    //         <textarea
    //           id="message"
    //           name="message"
    //           rows="7"
    //           placeholder="Your message here"
    //           required
    //         ></textarea>
    //       </div>
    //       <div className="form-group-group">
    //         <button type="submit">Submit</button>
    //       </div>
    //     </form>
    //   </div>
    //   <div
    //     style={{
    //       backgroundColor: "white",
    //       height: "100px",
    //       paddingTop: "50px",
    //     }}
    //   >
    //     @copyright 2024 all right reserved by team Captain Diginaut
    //   </div>
    // </div>

    <div className="main-container-home max-md:text-xs">
      <div className="top-section-home flex flex-col -translate-y-[1px] pb-24">
        <Header />
        <h2 className="text-3xl max-md:text-2xl pt-8 max-md:pt-4 font-bold text-center max-md:mb-5">
          Are you Concerned about your child?
        </h2>
        <div className="main-grid [&.main-grid]:max-lg:grid-cols-1 [&.main-grid]:max-md:px-5">
          <div className="left-grid [&.left-grid_img]:max-md:h-20 [&.left-grid>div]:max-md:h-24">
            <div>
              <img src={cyberRisk} alt="cyber risk" className="scale-125" />
              <TextCard text="Getting exposed to cyber  risks" />
            </div>
            <div>
              {" "}
              <TextCard text="Clicking on unsafe links" />
              <div>
                {" "}
                <img src={burglar} alt="cyber risk" />
              </div>
            </div>
            <div>
              <div>
                {" "}
                <img src={mentalHealth} alt="cyber risk" />
              </div>
              <TextCard text=" Impact of technology on your child's mental and physical health" />
            </div>
            <div>
              {" "}
              <TextCard text="Downloading apps that are not appropriate for their age" />
              <div>
                {" "}
                <img src={unsafe} alt="cyber risk" />
              </div>
            </div>
          </div>

          <div className="right-grid [&.right-grid_iframe]:max-lg:w-full">
            <div className="youtube w-full">
              <YouTube
                videoId="dXEG1iC1yjA"
                opts={{
                  playerVars: {
                    autoplay: 1,
                  },
                }}
                className="w-full"
              />
            </div>

            <h3 className="right-grid-text leading-6 sm:leading-8 mt-10 ">
              Join <span>Captain Diginaut</span> and conquer the challenges of
              digital world
            </h3>
          </div>
        </div>
        <h3 className="hero-bottom-text leading-8">
          Check out our{" "}
          <span>
            <Link to="/SignIn">Quizzes</Link>
          </span>{" "}
          , Play, Win and Learn{" "}
        </h3>
        <img
          src={planet}
          alt="planet stars background"
          width="160px"
          height="160px"
          className="absolute right-0 top-[90%] max-md:hidden"
        />
      </div>

      <div className="second-section-home mt-16 relative [&.second-section-home_h3]:max-md:px-2 [&.second-section-home]:max-md:px-6">
        <h3 className="text-4xl font-light text-center max-md:text-2xl">
          In our big, exciting DigiVerse, being safe is just as important as
          having fun!
        </h3>
        <div className="second-section-grid [&.second-section-grid]:max-md:grid-cols-1">
          <CloudText text="learn how to take care of devices" />
          <CloudText text="go beyond theory & know practical use of tools like MS Word, PPT etc" />
          <CloudText text="know tips & trick for effectively using search, emails, emojis etc." />
          <CloudText text="apply security setting & be safe" />
        </div>
        <img
          src={stars}
          alt="stars background"
          width="92px"
          height="92px"
          className="absolute left-10 top-[75%] z-10"
        />
      </div>

      <div className="third-section-home">
        <div>
          <div className="cloud-line-top [&.cloud-line-top]:2xl:-top-24" />
          <div className="cloud-line-bottom [&.cloud-line-bottom]:2xl:-bottom-20" />
        </div>
        <div className="third-section-content [&.third-section-content]:max-md:text-xl">
          <h2 className="third-section-title [&.third-section-title]:max-md:text-3xl [&.third-section-title]:max-md:pt-10">
            How do you want your child to grow up?
          </h2>
          <div className="pointer-grid [&.pointer-grid]:max-md:grid-cols-1 [&.pointer-grid]:max-md:gap-3">
            <Pointer
              src={tick}
              text="A Good Digital Leader & Citizen? One who uses technology for everyone’s benefit? "
            />
            <Pointer
              src={cross}
              text="Someone who gets Scammed & faces Mental & Health Risks? "
            />
          </div>
          <h3 className="leading-10">
            Nurture your kids with Digital life-skills
          </h3>
          <div className="third-section-grid [&.third-section-grid]:max-sm:grid-cols-1 [&.third-section-grid]:max-lg:grid-cols-2">
            <SquareCard text="Good Digital Habits" />
            <SquareCard text="Avoid Digital Risk" />
            <SquareCard text="Use Technology but Responsibly" />
            <SquareCard text="Tips and Tricks" />
          </div>
          <h3 className="leading-10">
            because today's prodigies need to be PRO-Digital
          </h3>
          <img
            src={triangles}
            alt="triangles background"
            width="75px"
            height="75px"
            className="absolute left-0 md:left-6 top-[10%] z-10 max-md:top-[7%] max-md:opacity-60"
          />
          <img
            src={triangles}
            alt="triangles background"
            width="75px"
            height="75px"
            className="absolute right-6 top-[80%] z-10 max-md:top-[80%] max-md:opacity-60"
          />
        </div>
      </div>

      <div className="fourth-section-home [&.fourth-section-home]:max-md:bg-[length:contain] [&.fourth-section-home]:max-md:bg-[position:center]">
        <h2 className="fourth-section-title 2xl:pt-3 bg-white px-5 rounded-lg w-fit mx-auto [&.fourth-section-title]:max-md:text-2xl [&.fourth-section-title]:max-md:font-bold">
          Welcome to the DigiVerse
        </h2>
        <h3 className="fourth-section-heading [&.fourth-section-heading]:max-md:text-xl [&.fourth-section-heading]:max-md:pt-5 px-10">
          🌟 Hey Digital Warriors! <br />
          Ready to Become Digital Literacy Masters? 🌟
        </h3>

        <div className="fourth-section-grid [&.fourth-section-grid]:max-md:grid-cols-1 [&.fourth-section-grid]:max-md:px-5 [&.fourth-section-grid]:max-md:py-5 [&.fourth-section-grid]:max-md:gap-10">
          <DigitalLiteracyCard
            title="Be a Privacy Pro!"
            description="Learn how to keep your secrets safe and sound from sneaky spies"
            src={privacy1}
            alt="privacy"
            longDescription="Just like you lock your front door, lock down your online world! Learn how to use strong, unique passwords, guard your OTPs like treasure, and think twice before sharing personal details. How to keep your social media profiles private, avoid oversharing, and use antivirus software to guard against sneaky threats!”"
          />
          <DigitalLiteracyCard
            title="Smart Surfing!"
            description="Learn tricks to navigate through vast ocean of internet & pick knowledge pearls"
            src={privacy2}
            alt="privacy"
            longDescription="Smart Surfing: Explore internet like an awesome surfer and learn important things! Stick to safe sites, double-check internet information, and avoid clicking on unknown links. Learn tip & tricks that can help you find better information much faster! Keep safe from online trolling & cyberbullying."
          />
          <DigitalLiteracyCard
            title="Friend or Foe?"
            description="Play all your favorite games while staying safe and sound."
            src={privacy3}
            alt="privacy"
            longDescription="Recognize Digital Villains and Friends: “Friend or Foe: Not everyone online is who they say they are! Learn to spot the difference between friendly messages and sneaky scams. Watch out for phishing attempts that try to steal your info, rob you of money, and be careful about accepting friend requests from strangers. Stand up against cyberbullying and not letting digital villains bring you down.”"
          />
          <DigitalLiteracyCard
            title="Social Media Smarts: Navigating the Digital Jungle"
            description="Discover how to use social media well without running into digital monsters."
            src={privacy4}
            alt="privacy"
            longDescription="Think before you post, because what goes online stays online. Learn how to keep your personal information private and use settings that safeguard you. Get to know tips & tricks to use social media for your benefits and at the same time avoid online drama, fake news, and other such problems. Be a social media star who knows how to stay safe, smart, and kind!”"
          />
          <DigitalLiteracyCard
            title="Mastering Digital Citizenship"
            description="Play all your favorite games while staying safe and sound."
            src={privacy5}
            alt="privacy"
            longDescription="Being part of the online world is just like being part of your neighbourhood—respect, kindness, and responsibility matters! Practice good digital manners & etiquette by treating others with respect, avoid hurtful comments, and learn how to balance your time between the online and physical worlds. Too much screen time can affect your mental & physical health."
          />
          <DigitalLiteracyCard
            title="Practical Tips for Mastering Technology"
            description="Discover how to use social media well without running into digital monsters."
            src={privacy6}
            alt="privacy"
            longDescription="Help your child unlock the full potential of technology and how to use them in practical, real-life situations! From sending & managing emails and mastering Google search for reliable information, to using MS Word for reports, Excel for organizing data, and PowerPoint for creative presentations, these skills prepare them for school and beyond."
          />
        </div>
      </div>

      <div className="fifth-section-home py-20 md:px-10">
        <div className="flex max-w-screen-xl mx-auto text-white py-10 justify-between max-md:flex-col max-md:px-10 max-md:py-0">
          <div className="flex flex-col">
            <div>More About Us</div>
            <div className="text-4xl font-light mt-4 mb-8 max-md:max-w-[480px]">
              Special Care For digital Age
            </div>
            <div className="md:max-w-[45vw]">
              we provide comprehensive programs designed to equip your child
              with the critical digital literacy skills they need to succeed in
              an increasingly digital world.
            </div>
          </div>
          <div className="flex space-x-20 pt-12 max-md:8 justify-center max-md:space-x-10">
            <div className="p-2 px-3 bg-white">
              <img
                src={comic1}
                alt="phishing comic"
                // height="260px"
                // width="180px"
              />
            </div>
            <div className="p-2 bg-white">
              <img
                src={comic2}
                alt="comic"
                // height="260px"
                // width="180px"
                className="object-cover"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center text-white mt-6">
          <a
            href="https://www.youtube.com/@captaindiginaut"
            target="_blank"
            rel="noreferrer"
            className="bg-gray-800 rounded-full p-2"
          >
            <YoutubeIcon />
          </a>
          <a
            href="https://www.instagram.com/captain_diginaut/"
            target="_blank"
            rel="noreferrer"
            className="bg-gray-800 rounded-full p-2 ml-4"
          >
            <InstagramIcon />
          </a>
        </div>
      </div>

      <div className="flex flex-col justify-center contact-section-home max-w-[1440px] relative mx-auto p-16 max-md:p-10">
        <img
          src={contactBg}
          alt="contact"
          className="mx-auto absolute w-[88vw] h-full max-md:hidden"
        />
        <div className="z-10 px-20 py-12 relative max-md:px-0">
          <img
            src={stars}
            alt="stars background"
            className="absolute left-32 top-[2%] w-[7vw] h-[7vw] lg:w-[92px] lg:h-[92px]"
          />
          <img
            src={stars}
            alt="stars background"
            className="absolute right-10 top-[85%] w-[6vw] h-[6vw] lg:w-[84px] lg:h-[84px]"
          />
          <p className="text-center text-lg">Contact Us</p>
          <h3 className="text-primary text-4xl font-light text-center mb-12">
            Let's get in touch
          </h3>
          <ContactForm />
        </div>
      </div>

      <div className="text-center py-8 font-light px-10">
        @copyright 2024 all right reserved by team Captain Diginaut
      </div>
    </div>
  );
};

export default Screen1;
