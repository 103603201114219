import React, { useEffect, useState } from "react";
import "../styles/home.css";
import axios from "axios";
import avatar from "../avatar.png";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../GlobalContext.js";
import gem from "../gem.png";
import crown from "../crown.png";
import image from "../image.png";
import logout from "../logout.png";
import RulesModal from "./RulesModal.js";

const LeaderboardTable = ({ leaderboardData }) => {
  return (
    <div className="leaderboard-container">
      <table className="leaderboard-table">
        <thead>
          <tr>
            <th>Weekly</th>
            <th>Monthly</th>
            <th>All Time</th>
          </tr>
        </thead>
        <tbody>
          {" "}
          {leaderboardData.allTime.map((item, index) => (
            <tr key={index}>
              <td>
                {index + 1}.{leaderboardData.weekly[index]?._id.username}
              </td>
              <td>
                {index + 1}.{leaderboardData.monthly[index]?._id.username}
              </td>
              <td>
                {index + 1}.{leaderboardData.allTime[index]?._id.username}
              </td>
            </tr>
          ))}{" "}
        </tbody>
      </table>
    </div>
  );
};

const DigitalHeroZone = () => {
  const [points, setPoints] = useState("");
  const [username, setUsername] = useState("");
  const { globalState, setGlobalState } = useGlobalContext();
  const email = globalState.email;
  // const email='luckyfrog219@yahoo.com';
  const [userData, setUserData] = useState("");
  const [curLevel, setCurLevel] = useState(1);
  const [leaderboard, setLeaderboard] = useState();
  const [totalScore, setTotalScore] = useState(0);

  const [rulesModal, setRulesModal] = useState(false);

  const showModal = () => {
    setRulesModal(true);
  };
  const hideModal = () => {
    setRulesModal(false);
  };

  useEffect(() => {
    const getTopics = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_serverUrl}userProfile/${email}`,
          { email: email },
        );
        setUserData(response.data);
        setUsername(response.data.username);
        setCurLevel(response.data.level);
        setPoints(response.data.levelTotalScore);
        setTotalScore(response.data.totalScore);
        setGlobalState({
          ...globalState,
          curLevel: curLevel,
          username: response.data.username,
          totalScore: response.data.totalScore,
          gems: response.data.gemEarned,
        });

        const lboardData = await axios.post(
          `${process.env.REACT_APP_serverUrl}leaderboard`,
        );
        setLeaderboard(lboardData.data);
      } catch (err) {
        console.error("Error fetching values", err);
      }
    };
    if (!userData) {
      // Check if userData is empty before fetching
      getTopics();
    }

    return () => {};
  }, [email]);

  const [curTopic, setCurTopic] = useState("");

  const handleCurtopic = (topicName) => {
    let selectedTopic;
    selectedTopic = userData.topics.find(
      (topic) => topic.topicName === topicName,
    );
    if (selectedTopic) {
      selectedTopic.levels.sort((a, b) => a.levelId - b.levelId);
      setCurTopic(selectedTopic);
      setGlobalState({
        ...globalState,
        topic: topicName,
      });
    }
  };

  let totalLevels = 0;
  if (Array.isArray(userData.topics)) totalLevels = userData.topics.length;

  const renderButtons = () => {
    const buttons = [];
    // for (let i = 1; i <= totalLevels; i++) {
    // Optional Chaining (?.): If you're using modern JavaScript (ES2020+), you can use optional chaining to access properties of potentially undefined objects without causing errors. For example:
    // if curTopic.levels is not completed fetching we will not get error. When fetched it will be updated.
    curTopic.levels?.forEach((level) => {
      buttons.push(
        <Link to={`/quiz/${level.quizUrl}/${email}`}>
          <button key={level.levelId}>Level {level.levelId}</button>
        </Link>,
        // <button key={i} onClick={() => handleCurtopic(`Topic ${i}`)}>Level {i}</button>
        // <Link to={`/quiz/${quizId}`}></Link>
      );
    });

    return buttons;
  };
  const navigate = useNavigate();
  const handleLogout = () => {
    navigate("/");
    window.location.reload();
  };

  return (
    <div className="container">
      <header>
        <div className="header-align">
          <Link to="#">
            <img src={avatar} alt="avatar" className="logo" />
          </Link>
          <h1>Digital Hero Zone</h1>
          <div className="logout-btn">
            <h3>Hello! {userData.username}</h3>
            <img src={logout} alt="logout" onClick={handleLogout} />
          </div>
        </div>
        <div className="welcome-content">
          <h1 className="heading-mobile">Digital Hero Zone</h1>
          <p>Welcome to the Digital Battlefield!</p>
          <p>
            Level up your skills and unlock new challenges as you master the
            digital world
          </p>
        </div>
      </header>
      <div className="grid grid-gap-3">
        <div className="grid-2 grid-space">
          <div className="left-section-section">
            <p>
              This is a huge <b>Digital Library</b> that houses important data &
              protects many secrets which Technologists have been passing
              through generations. A <b>sneaky virus</b> called the Data
              Corrupter is all set to attack this House of Ultimate Digital
              Knowledge and hack well-kept secrets!
            </p>
            <p>
              Captain Diginaut is on her mission to save the library. She needs
              to use all her Digital Powers but it's a race against time &{" "}
              <b>she needs your Help!</b>
            </p>
            <p>
              <b>Play the Quizzes, pass the Levels, win the Gems</b> and clear
              this stage to help Captain Diginaut & become{" "}
              <b>the LEGEND - the Legendary Digital Hero!!</b>
            </p>
          </div>
          <div className="current-status">
            <div className="status-image">
              <img src={image} />
            </div>
          </div>
        </div>
        <div className="grid-2 grid-space">
          <div className="profile">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h2 s>🌟 Warrior {username}</h2>
              <div style={{ textAlign: "center" }}>
                <img src={crown} style={{ width: "30px", height: "40px" }} />
                <p>{totalScore}</p>
              </div>
            </div>

            <table>
              <tbody>
                <tr style={{ height: "50px" }}>
                  <td>Level</td>
                  <td>
                    <strong>{curLevel}</strong>
                  </td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td>Points</td>
                  <td>
                    <strong>{points}</strong>
                  </td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td>Gems</td>
                  <td>
                    <div className="flex items-center">
                      <span>{globalState.gems}</span>
                      <img src={gem} alt="Gem" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ maxWidth: "400px", margin: "auto" }}>
              <p style={{ fontSize: "20px", margin: "10px 0" }}>
                Continue to play Quizzes and keep clearing the Levels.
              </p>
              <h3 style={{ fontSize: "20px", fontWeight: 500 }}>
                Save the Library of Wisdom and be the Digital Hero.
              </h3>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 300,
                  marginTop: "20px",
                  cursor: "pointer",
                  textAlign: "left",
                  marginLeft: "-1.1vw",
                }}
              >
                Check <u onClick={showModal}>Rules</u> and get started
              </p>
              {rulesModal && <RulesModal closeModal={hideModal} />}
            </div>
          </div>
          <div>
            <p>
              Dive into fun quizzes to help out your friend Captain Diginaut as
              she takes on the villains of DigiVerse.
              <div style={{ marginBottom: "45px" }}>
                As you play, also learn you awesome Tech Tricks, Digital Good
                Habits & Safety tips!
              </div>
            </p>
            <div className="asidebtn">
              {Array.isArray(userData.topics) &&
                userData.topics.map((topic) => {
                  return (
                    <Link
                      to={`/quiz/${topic.levels[curLevel - 1].quizUrl}/${email}`}
                    >
                      <button
                        id={topic.topicName}
                        value={topic.topicName}
                        onClick={() => handleCurtopic(topic.topicName)}
                      >
                        {topic.topicName}
                      </button>
                    </Link>
                  );
                })}{" "}
            </div>
          </div>
        </div>
        <div className="grid-2 grid-space ">
          <div className="leaderboard-content">
            <h3>
              Can you top the leader board and show you're the ultimate digital
              champion
            </h3>
          </div>
          <div className="leaderboard">
            <h1 style={{ marginBottom: "30px" }}>Leaderboard</h1>
            {leaderboard && <LeaderboardTable leaderboardData={leaderboard} />}
          </div>
        </div>
      </div>
      <a target="_blank" href="https://forms.gle/zNSChvNRubefYaTM6">
        <div className="home-container">
          <u>Feedback Form</u>
        </div>
      </a>
    </div>
  );
};

export default DigitalHeroZone;
