import React from "react";

export const CloudText = ({ text }) => {
  return (
    <div>
      <div className="cloud">
        <div className="cloud-text text-xl max-md:text-lg max-md:leading-5">
          {text}
        </div>
      </div>
    </div>
  );
};
